import { useState, useEffect } from 'react'
import { dateTimeFormat } from '../utils/formatter'
import ReactTable from './ReactTable'
import LambdaFetch from '../functions/FetchFromLambda'
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core'
const logCols = [
  {
    accessorKey: 'action',
    header: 'Action',
    filterFn: 'contains',
  },
  {
    accessorKey: 'page',
    header: 'Page',
    filterFn: 'contains',
  },
  {
    accessorKey: 'user',
    header: 'User',
    filterFn: 'contains'
  },
  {
    accessorKey: 'payloadDesc',
    header: 'Payload',
    filterFn: 'contains'
  },
  {
    accessorKey: 'desc',
    header: 'Description',
    filterFn: 'contains'
  },
  {
    accessorKey: 'date',
    header: 'Date/Time',
    sortingFn: 'datetime',
    Cell: ({ cell }) => dateTimeFormat(cell.getValue())
  }
]

const Logs = props => {
  const [state, setstate] = useState({
    isLoading: true,
    isFetching: true, // TODO
    logs: null
  })
const [params, setParams] = useState(null)

  useEffect(() => {
    async function fetchMyAPI () {
      fetchData()
    }
    fetchMyAPI()
  }, [])
  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'admin',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'read-audit'
        }),
        '',
        props.fetchInitialData.credentials
      )
      setstate({
        ...state,
        logs: resp.data.audit.map(row => {
          return {
            ...row,
            payloadDesc: row.payload ?  (
            <div className="editLink" onClick={() => {
              setParams(row.payload)
            }}>
              {`${row.payload.slice(0,50)}......`}
            </div>
            ) : '',
            desc: row.description ?  (
              <div className="editLink" onClick={() => {
                setParams(JSON.stringify({description: row.description}))
              }}>
                {`${row.description.slice(0,50)}......`}
              </div>
              ) : ''
          }
        }),
        isLoading: false,
        isFetching: false
      })
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <>
    {params && (
        <Dialog
          fullWidth
          scroll={'paper'}
          open={params}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogContent dividers>
         <RenderParams data={JSON.parse(params)}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setParams(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <div style={{ margin: '1rem' }}>
        <ReactTable
          isLoading={state.isFetching}
          cols={logCols}
          data={state.logs}
        />
      </div>
    </>
  )
}

export default Logs


const RenderParams = ({ data, parentKey = null, indentLevel = 0 }) => {
  return (
    <div style={{ marginLeft: `${indentLevel * 20}px` }}>
      {Object.keys(data).map(key => (
        <div key={key}>
          {typeof data[key] !== 'object' ? (
            <Typography variant='body1'>
              {key}: {data[key]}
            </Typography>
          ) : (
            <div>
              {parentKey && <strong>{parentKey}:</strong>} {key}:
              <RenderParams data={data[key]} parentKey={key} indentLevel={indentLevel + 1} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
